import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InformationSidePanelModalService } from 'gain-lib/information-side-panel-modal/information-side-panel-modal.service';
import { LocationFormHelper } from 'gain-web/shared-modules/geography/location-form-helper.service';
import { ApiClient } from 'gain-web/shared-services/api-client.generated.service';
import { Observable, Subject, of } from 'rxjs';
import { count, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LocationFormComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject();

  // Prevent enter press to prevent textarea from adding empty rows
  @HostListener('keypress', ['$event'])
  onKeyPress(keyPress: KeyboardEvent) {
    if (keyPress.key === 'Enter') {
      keyPress.preventDefault();
    }
  }

  @Input()
  countryLabel: string = 'Country';

  @Input()
  regionLabel: string = 'Region';

  @Input()
  countryControl!: FormControl<string | null>;

  @Input()
  regionControl!: FormControl<string | null>;

  @Input()
  disabledCountries?: string[];

  @Input()
  displayCountryInfo: boolean = false;

  @Input()
  displaySocialTaxInfo: boolean = false;

  @Input()
  countryRequired: boolean = false;

  @Input()
  regionRequired: boolean = false;

  filteredCountries!: Observable<ApiClient.ICountryDto[]>;

  filteredRegions!: Observable<ApiClient.RegionDto[]>;

  @Output()
  optionSelected = new EventEmitter<void>();

  constructor(
    public locationFormHelper: LocationFormHelper,
    private _infoPanel: InformationSidePanelModalService,
  ) {}

  ngOnInit() {
    this.locationFormHelper.init({
      countryControl: this.countryControl,
      regionControl: this.regionControl,
      onDestroy$: this.onDestroy$,
    });

    this.filteredCountries = this.locationFormHelper.getCountryOptions(
      this.countryControl,
    );

    this.countryControl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.optionSelected.emit();
        this.filteredRegions = this.locationFormHelper.getRegionOptions(
          this.countryControl,
        );
      });
  }

  clearSelection() {
    this.countryControl.setValue(null);
  }

  onSearchCountry(event: string) {
    this.filteredCountries = this.locationFormHelper
      .getCountryOptions(this.countryControl)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((countries) =>
          of(
            countries.filter(
              (country) =>
                country.code.toLowerCase().startsWith(event.toLowerCase()) ||
                country.name.toLowerCase().startsWith(event.toLowerCase()),
            ),
          ),
        ),
      );
  }

  onSearchRegion(event: string) {
    this.filteredRegions = this.locationFormHelper
      .getRegionOptions(this.countryControl)
      .pipe(
        takeUntil(this.onDestroy$),
        switchMap((regions) =>
          of(
            regions.filter(
              (region) =>
                region.name.toLowerCase().includes(event.toLowerCase()) ||
                region.code.toLowerCase().includes(event.toLowerCase()),
            ),
          ),
        ),
      );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  showCountryInformationModal() {
    this._infoPanel.open({
      title: 'FMV country',
      contentHtml: `<p>In certain locations, specific fair market values are required. Examples
    include Merchant Banker Valuation (India), 30 Day Average (Italy), Lowest
    Fair Market Value of the Day (Germany), etc. If you would like to provide a
    specific fair market such as these, add an additional fair market value and
    select the applicable country it should be used for.</p>`,
    });
  }

  showSocialTaxInformationModal() {
    this._infoPanel.open({
      title: 'Social tax',
      contentHtml: `<p>
    When this input is populated, this is the only country that will be
    considered for social tax purposes. All other data inputs (e.g. CoCs) will
    be ignored.
  </p>`,
    });
  }

  protected readonly count = count;
}
