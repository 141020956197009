import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ContentChild,
  input,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AsyncRequestHelper } from 'gain-lib/ga-async-request/async-request-helper';
import { TableErrorMessageComponent } from 'gain-lib/ga-table/src/ga-table/mat-table-wrapper/table-error-message/table-error-message.component';
import { TableZeroResultsMessageComponent } from 'gain-lib/ga-table/src/ga-table/mat-table-wrapper/table-zero-results-message/table-zero-results-message.component';
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

type TableState = 'LOADING' | 'LOADED' | 'EMPTY' | 'ERROR';

@Component({
  selector: 'gax-mat-table-wrapper',
  templateUrl: './mat-table-wrapper.component.html',
  styleUrl: './mat-table-wrapper.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MatTableWrapperComponent implements OnInit, OnDestroy {
  onDestroy$ = new Subject();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _rows$ = new BehaviorSubject<any[] | null>(null);
  empty$ = this._rows$.pipe(map((r) => r == null || r.length === 0));

  @ContentChild(TableZeroResultsMessageComponent)
  protected _zeroResultsMessageCmp?: TableZeroResultsMessageComponent;

  @ContentChild(TableErrorMessageComponent)
  protected _tableErrorMessageCmp?: TableErrorMessageComponent;

  @Input()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  request?: AsyncRequestHelper<any>;

  zeroFilteredResultsHeader = input<string>('No results found');
  zeroFilteredResultsMessageHtml = input<string>(
    'Change your search query or adjust your filter criteria.',
  );
  zeroResultsHeader = input<string>('No data has been added yet');
  zeroResultsMessageHtml = input<string>();

  protected emptyTableHeader = computed(() => {
    return this.totalLength() === 0
      ? this.zeroResultsHeader()
      : this.zeroFilteredResultsHeader();
  });

  protected emptyTableMessageHtml = computed(() => {
    return this.totalLength() === 0
      ? this.zeroResultsMessageHtml()
      : this.zeroFilteredResultsMessageHtml();
  });

  totalLength = input<number>();

  private _dataSourceListener?: Subscription;

  @Input()
  set dataSource(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any[] | MatTableDataSource<any> | Observable<any> | null,
  ) {
    if (value instanceof MatTableDataSource) {
      this._dataSourceListener?.unsubscribe();
      this._dataSourceListener = value
        .connect()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((rows) => {
          this._rows$.next(rows);
        });
    } else if (value instanceof Observable) {
      this._dataSourceListener?.unsubscribe();
      value.pipe(takeUntil(this.onDestroy$)).subscribe((rows) => {
        this._rows$.next(rows);
      });
    } else {
      this._rows$.next(value);
    }
  }

  protected tableState$?: Observable<TableState>;

  ngOnInit() {
    if (this.request != null) {
      this.tableState$ = combineLatest([
        this.request.process.state$,
        this._rows$.asObservable(),
        this.request.successResult$,
      ]).pipe(
        map(([state, rows, successResult]) => {
          switch (state) {
            case 'NEW':
            case 'PENDING': {
              return 'LOADING';
            }
            case 'SUCCESS': {
              const isEmpty = rows == null || rows.length === 0;
              const isSuccessResult: boolean =
                successResult &&
                successResult.totalItems &&
                successResult.totalItems > 0;
              return isEmpty && !isSuccessResult ? 'EMPTY' : 'LOADED';
            }
            case 'FAILED':
            default:
              return 'ERROR';
          }
        }),
      );
    } else {
      this.tableState$ = this._rows$.pipe(
        map((rows) => {
          if (rows == null) {
            return 'LOADING';
          }
          if (rows.length > 0) {
            return 'LOADED';
          } else return 'EMPTY';
        }),
      );
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
