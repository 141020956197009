@use "sass:map";
@use "../../../assets/sass/utils/variables";
@use "../../../assets/sass/utils/palette";

gax-breadcrumbs {
  .main-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    height: 1.5rem;
    background: transparent;
  }

  .default-text {
    height: 1.5rem;
    //font-family: "Open Sans";
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    /*&:last-of-type {
      font-weight: 600 !important;
    }*/

    mat-icon.mat-icon {
      color: map.get(palette.$grey-palette, 700);
    }
  }

  .active-link {
    font-weight: 600;
  }

  .breadCrumb-link {
    text-decoration: inherit;
    //color: inherit;
    color: variables.$black;
  }
}
