<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<ng-content select=".drag-drop-icon"></ng-content>
<div class="cell-content" #editValue [class.edited]="edited">
  @if (!!editing()) {
    @if (valueType() === "string") {
      <input
        (keydown.escape)="exitEditMode(true)"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        class="cell-input"
        (input)="onInputChange($event)"
        [value]="userEditableValue()"
        [min]="min()"
        [max]="max()"
        [placeholder]="placeholder()"
      />
      <div class="cell-edit-icons">
        @if (computedErrorsTooltip()) {
          <mat-icon class="error-icon" [matTooltip]="computedErrorsTooltip()"
            >error
          </mat-icon>
        } @else {
          <mat-icon
            class="cursor-pointer"
            [matTooltip]="isClean ? '' : 'Commit changes'"
            [class.clean]="isClean"
            (click)="updateValue(editValueInput.value, $event)"
            >check
          </mat-icon>
        }
        <mat-icon
          matTooltip="Cancel changes"
          (click)="exitEditMode(true)"
          class="cursor-pointer"
          >close
        </mat-icon>
      </div>
    } @else {
      <input
        type="number"
        (keydown.escape)="exitEditMode(true)"
        (keydown.enter)="updateValue(editValueInput.value, $event)"
        #editValueInput
        (input)="onInputChange($event)"
        class="cell-input"
        [value]="userEditableValue()"
        [placeholder]="placeholder()"
      />
      <div class="cell-edit-icons">
        @if (computedErrorsTooltip()) {
          <mat-icon class="error-icon" [matTooltip]="computedErrorsTooltip()"
            >error
          </mat-icon>
        } @else {
          <mat-icon
            class="cursor-pointer"
            matTooltip="Save changes"
            (click)="updateValue(editValueInput.value, $event)"
            >check
          </mat-icon>
        }

        <mat-icon
          matTooltip="Cancel changes"
          (click)="exitEditMode(true)"
          class="cursor-pointer"
          >close
        </mat-icon>
      </div>
    }
  } @else {
    <div
      [ngClass]="{
        'cell-display-value': true,
        'has-custom-icon': hasCustomIcon,
      }"
      (click)="enterEditMode(editValue)"
    >
      <span>
        <ng-content></ng-content>
      </span>
      <!-- if edited, show recycle icon, else show edit icon -->
      @if (computedErrorsTooltip()) {
        <mat-icon class="error-icon" [matTooltip]="computedErrors()[0]"
          >error
        </mat-icon>
      } @else {
        <mat-icon class="edit-icon">edit</mat-icon>
        <mat-icon class="custom-icon" *ngIf="icon">{{ icon() }}</mat-icon>
      }
    </div>
  }
</div>
