import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'gax-section-content-details',
  templateUrl: './section-content-details.component.html',
  styleUrls: ['./section-content-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SectionContentDetailsComponent {}
