import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  input,
  Input,
  model,
  OnInit,
  Output,
  signal,
  viewChild,
  ViewEncapsulation,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PageSectionTableHeaderFiltersComponent } from 'gain-lib/page/page-section/page-section-table-header/page-section-table-header-filters/page-section-table-header-filters.component';
import { isNullishOrWhitespace } from 'gain-lib/string';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

export interface TableBatchAction {
  icon: string;
  tooltip: string;
  pending: boolean;
  action: () => void;
}

@Component({
  selector: 'gax-page-section-table-header',
  templateUrl: './page-section-table-header.component.html',
  styleUrl: './page-section-table-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class PageSectionTableHeaderComponent implements OnInit, AfterViewInit {
  private _query$ = new Subject<string | null>();

  readonly query$ = this._query$.asObservable();

  public readonly filterCmp = contentChild(
    PageSectionTableHeaderFiltersComponent,
  );

  protected readonly searchInputElement =
    viewChild<ElementRef<HTMLInputElement>>('searchInput');

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    this._query$
      .pipe(
        map((query) => (isNullishOrWhitespace(query) ? null : query)),
        distinctUntilChanged(),
        debounceTime(this.searchChangeDebounce ?? 0),
        takeUntilDestroyed(),
      )
      .subscribe((query) => {
        this.setSearchQuery(query);
      });
  }

  setSearchQuery(
    query: string | null | undefined,
    options: { emitEvent: boolean } = { emitEvent: true },
  ) {
    const sq = this._searchQuery();
    const noChange = (sq == null && query == null) || sq === query;
    const changed = !noChange;
    if (changed) {
      this._searchQuery.set(query ?? null);
      this._setSearchElementInput(query ?? null);
      if (options.emitEvent) {
        this.searchChange.emit(query);
      }
    }
    const queryQueryParam = this._route.snapshot.queryParams.query;
    if (queryQueryParam != null && query == null) {
      setTimeout(async () => {
        await this._router.navigate([], {
          queryParams: { query: null },
          queryParamsHandling: 'merge',
        });
      });
    }
  }

  private _setSearchElementInput(query: string | null) {
    const searchEl = this.searchInputElement();
    if (searchEl != null) {
      searchEl.nativeElement.value = query ?? '';
      return true;
    }
    return false;
  }

  @Input()
  @HostBinding('class.removeHeaderBorder')
  removeHeaderBorder: boolean = false;

  @Input()
  searchChangeDebounce: number | null = 300;

  @Output()
  searchChange: EventEmitter<string | null> = new EventEmitter();

  isFocused!: boolean;

  @HostListener('focus', ['$event']) onFocus() {
    this.isFocused = true;
  }

  @HostListener('blur', ['$event']) onBlur() {
    this.isFocused = false;
  }

  @HostBinding('class.reverse-headers') @Input() reverseHeaders: boolean =
    false;

  @Input()
  searchbar: boolean = false;

  protected onSearchChange(event: KeyboardEvent) {
    const searchValue = (event.target as HTMLInputElement).value;
    this._query$.next(searchValue);
  }

  public clearSearch() {
    this._query$.next(null);
  }

  searchQuery = computed(() => this._searchQuery());

  private _searchQuery = signal<string | null>(null);

  showFilter = input<boolean | null>(null);

  showFilterToggle = computed(
    () => this.showFilter() === true || this.filterCmp() !== undefined,
  );

  @Output()
  filterToggle = new EventEmitter<boolean>();
  filterActive = model<boolean>(false);

  toggleFilter(showFilters?: boolean) {
    this.filterActive.update((active) => showFilters ?? !active);
    this.filterToggle.emit(this.filterActive());
  }

  @Input()
  showActions: boolean = false;

  @Input()
  actions: TableBatchAction[] = [];

  ngOnInit() {
    if (this.showFilter() === true) {
      const cmpName = 'gax-page-section-table-header';
      if (this.filterCmp() == null) {
        console.warn(
          `When filters are enabled for '${cmpName}', 'gax-page-section-table-header-filters' component should be used to render filters.`,
        );
      }
    }
  }
  ngAfterViewInit() {
    const filterCmp = this.filterCmp();
    if (filterCmp != null && filterCmp.appliedFilterChips().length > 0) {
      filterCmp.filtersApplied.set(true);
      this.filterActive.set(true);
    }
    this._setSearchElementInput(this.searchQuery());
  }
}
