import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  Host,
  input,
  model,
  OnDestroy,
  Optional,
  Output,
} from '@angular/core';
import { PageSectionTableHeaderComponent } from 'gain-lib/page/page-section/page-section-table-header/page-section-table-header.component';
import { GaFilterChip } from 'gain-lib/forms/src/filter-chip';

import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gax-page-section-table-header-filters',
  templateUrl: './page-section-table-header-filters.component.html',
  styleUrl: './page-section-table-header-filters.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PageSectionTableHeaderFiltersComponent
  implements AfterContentInit, OnDestroy
{
  onDestroy$ = new Subject();
  @Output()
  public readonly apply = new EventEmitter<void>();
  @Output()
  public readonly filterReset = new EventEmitter<void>();

  public readonly appliedFilterChips = input.required<GaFilterChip[]>();

  protected removeChip(chip: GaFilterChip) {
    chip.remove();
    this.applyFilters();
  }

  public readonly filterChipsDisplay = computed(() => {
    let chips = this.appliedFilterChips();

    const searchQuery = this.tableHeader.searchQuery();
    if (searchQuery != null) {
      chips = [
        {
          label: 'Search Query',
          displayValue: `"${searchQuery}"`,
          value: searchQuery,
          remove: () => {
            this.tableHeader.clearSearch();
          },
        } satisfies GaFilterChip,
        ...chips,
      ];
    }

    return chips;
  });
  public filtersApplied = model<boolean>(false);

  protected tableHeader: PageSectionTableHeaderComponent;

  constructor(
    private _route: ActivatedRoute,
    @Host() @Optional() tableHeader?: PageSectionTableHeaderComponent,
  ) {
    if (tableHeader == null) {
      throw new Error(
        'gax-page-section-table-header-filters must be a child of gax-page-section-table-header',
      );
    }
    this.tableHeader = tableHeader;

    const filterChipsDisplay$ = toObservable(this.filterChipsDisplay);

    filterChipsDisplay$.pipe(takeUntilDestroyed()).subscribe(() => {
      const hasChips = this.filterChipsDisplay().length > 0;
      const filtersApplied = this.filtersApplied();

      if (!hasChips && filtersApplied) {
        this.tableHeader.toggleFilter(false);
        this.clearFilters();
      }
    });
  }

  ngAfterContentInit() {
    this._route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      // listen to query parameters then on next CD cycle (hence setTimeout), check
      // to see if there are any filters and open filters and set them as applied
      setTimeout(() => {
        const hasSearchQuery = this.tableHeader.searchQuery() != null;
        const filtersApplied = this.filtersApplied();
        const shouldHaveFiltersApplied = hasSearchQuery
          ? this.filterChipsDisplay().length > 1
          : this.filterChipsDisplay().length > 0;

        if (shouldHaveFiltersApplied && !filtersApplied) {
          this.tableHeader.toggleFilter(true);
          this.filtersApplied.set(true);
        }
      });
    });
  }

  protected applyFilters() {
    this.apply.emit();
    this.filtersApplied.set(this.filterChipsDisplay().length > 0);
  }

  public clearFilters() {
    this.filtersApplied.set(false);
    this.resetFilters();
    this.applyFilters();
    setTimeout(() => {
      this.tableHeader.clearSearch();
    });
  }

  protected resetFilters() {
    this.filterReset.emit();
    this.tableHeader.clearSearch();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
