import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrencyCodeNamePipe } from 'gain-lib/money/src/currency-code-name.pipe';
import { CurrencyNamePipe } from 'gain-lib/money/src/currency-name.pipe';
import { MoneyAmountInputComponent } from 'gain-lib/money/src/money-amount-input/money-amount-input.component';
import { CurrencySelectComponent } from 'gain-lib/money/src/currency-select/currency-select.component';
import { MoneyInputComponent } from 'gain-lib/money/src/money-input/money-input.component';
import { MoneyFormatterDirective } from 'gain-lib/money/src/money-formatter.directive';
import { MoneyPipe } from 'gain-lib/money/src/money.pipe';
import { MoneyComponent } from 'gain-lib/money/src/money/money.component';
import { MatSelectModule } from '@angular/material/select';
import { CurrencyComponent } from './currency/currency.component';
import { GaFormsModule } from 'gain-lib/forms/src/forms.module';
import { LimitDecimalsModule } from 'gain-lib/limit-decimals/limit-decimals.module';

@NgModule({
  declarations: [
    MoneyComponent,
    CurrencyNamePipe,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyInputComponent,
    MoneyAmountInputComponent,
    CurrencySelectComponent,
    CurrencyComponent,
  ],
  exports: [
    MoneyComponent,
    CurrencyNamePipe,
    CurrencyCodeNamePipe,
    MoneyFormatterDirective,
    MoneyPipe,
    MoneyInputComponent,
    CurrencySelectComponent,
    CurrencyComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    LimitDecimalsModule,
    GaFormsModule,
  ],
  providers: [MoneyPipe],
})
export class GaMoneyModule {}
