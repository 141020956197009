@use "../../../assets/sass/utils/variables";

gax-select-form-field-deprecated-use-mat-select {
  display: flex;
  justify-content: flex-start;
  font-family: "Open Sans";
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 0.5rem;
  outline: none;
  margin-bottom: 1rem;
  min-height: 82px;
  // width: 23.875rem;

  .mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
  .mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
  .mat-mdc-standard-chip .mat-mdc-chip-action-label {
    overflow: hidden;
  }

  span.mat-mdc-chip-action.mdc-evolution-chip__action--presentational {
    max-width: 14rem;
  }

  &:focus-visible .control {
    border-bottom: 1px solid rgba(0, 0, 0, 1);
  }

  .ga-select-min-line {
    min-height: 31px;
  }

  .hint,
  .error {
    font-size: 0.75rem;
    font-weight: normal;
  }

  &.ng-invalid.ng-pristine.ng-untouched {
    .error {
      display: none;
    }
    .suffix .mat-warn {
      display: none;
    }
  }
  .error {
    color: variables.$red;
  }

  .gax-label {
    margin-bottom: 0.65rem;
    font-size: 1rem;
    font-weight: 300;
  }

  .required-asterisk {
    display: inline-block;
    transform: translateX(0.05rem);
  }

  .control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.47rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);

    .suffix {
      display: flex;
      gap: 0.5rem;
      height: 1.95rem;
      align-items: center;
    }

    &:has(.mat-mdc-chip-set) {
      min-height: 47px;
    }

    .mat-mdc-chip-set {
      padding-left: 4px;
    }

    .autocompleteInput {
      border: none;
      width: 100%;
      font-size: 24px;
      font-weight: 800;
      line-height: 1.8rem;
      background: transparent;

      &:focus-visible {
        outline: none;
      }
    }

    &:hover {
      cursor: pointer;
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }

    margin-bottom: 0.5rem;

    .suffix {
      display: flex;
      gap: 0.5rem;
    }
  }

  &.active {
    .control {
      border-bottom: 1px solid rgba(0, 0, 0, 1);
    }
  }

  &.ng-invalid.ng-touched {
    .control {
      border-bottom: 1px solid variables.$red;
    }
  }

  &.disabled {
    opacity: 0.5;
    user-select: none;
    pointer-events: none;

    .control {
      cursor: auto;

      &:hover {
        border-bottom: 1px solid rgba(0, 0, 0, 0.42);
      }
    }
  }

  .caret-wrapper {
    width: 15px;
    display: flex;
    align-items: center;
    height: auto;
    transform: translateY(-2px);

    .drop-down-caret {
      height: 11.62px;
      width: 11.62px;
      border-bottom: 1px solid;
      border-right: 1px solid;
      transform: rotateZ(45deg);
      user-select: none;
    }
  }

  .selected-value {
    transition: transform 0.2s;
    transform-origin: left;
    width: 100%;
    font-size: 1.5rem;
    font-weight: 800;
    letter-spacing: 0.05rem;
    min-height: 31px;
    align-items: center;
    line-height: 31px;
  }

  .overlay-on-top {
    position: relative;
    z-index: 1001;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .placeholder {
    color: #0009;
  }
}

.panel {
  width: 100%;
  box-sizing: border-box;
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: var(--mdc-theme-on-surface, #000);
  background-color: white;
  overflow: hidden;
  overflow-y: auto;
  max-height: 280px;

  display: flex;
  flex-direction: column;

  &.panelAbove {
    flex-direction: column-reverse;
    align-self: flex-end;
    transform: translateY(-0.5rem);
  }

  .search-bar {
    display: flex;
    padding: 1rem;
    border-bottom: 1px solid hsla(0, 0%, 90%, 1);
    border-top: 1px solid hsl(0deg, 0%, 90%);
    align-items: center;

    input {
      font-family: "Open Sans";
      font-size: 1rem;
      font-weight: 300;
      letter-spacing: 0em;
      padding-left: 1rem;
      border: none;
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .drop-down-options {
    overflow: auto;
  }

  .drop-down-options .disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
  }

  .select-all-option {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;

    &.selected {
      background-color: variables.$green-wash;
    }

    input[type="checkbox"] {
      position: relative;

      border: 2px solid black;
      border-radius: 0.188rem;
      background: none;
      cursor: pointer;
      line-height: 0;
      outline: none;
      padding: 0 !important;
      vertical-align: text-top;
      width: 1.125rem;
      height: 1.125rem;
      -webkit-appearance: none;
      opacity: 0.55;
    }

    input[type="checkbox"]:hover {
      opacity: 1;
    }

    input[type="checkbox"]:checked {
      background-color: hsla(93, 100%, 33%, 1);
      border: 2px solid hsla(93, 100%, 33%, 1);
      opacity: 1;
    }

    input[type="checkbox"]:before {
      content: "";
      position: absolute;
      right: 50%;
      top: 50%;
      width: 4px;
      height: 10px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      margin: -1px -1px 0 -1px;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 2;
    }
  }
}

// Remove - add to select css - viewEncaps
.ga-select-overlay-panel {
  height: 280px;
}
